import loadable from '@loadable/component';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';

/* Components */
const Home = loadable(() => import('../Components/Home'));
const Plans = loadable(() => import('../Components/Plans'));
const Forgot = loadable(() => import('../Components/Forgot'));
const RecoveryPwd = loadable(() => import('../Components/RecoveryPwd'));
const ActiveAccount = loadable(() => import('../Components/ActiveAccount'));
const Proyects = loadable(() => import('../Components/Proyects'));
const ExpoTeach = loadable(() => import('../Components/ExpoSchool'));
const CreateAccount = loadable(() => import('../Components/CreateAccount'));
const LoginAccount = loadable(() => import('../Components/LoginAccount'));
const DocumentsLegal = loadable(() => import('../Components/LegalDocuments'));
const WalletTeacher = loadable(() => import('../Components/WallTeacher'));
const RegisterTeacher = loadable(() => import('../Components/CreateAccountTeacher'));
const Course = loadable(() => import('../Components/Course'));
const Windows = loadable(() => import('../Components/Windows'));

/* Components student */
const HomeStudent = loadable(() => import('../Components/HomeStudent'));

const Paths = () => (
    <Router>
      <Routes>
        <Route  path='/' element={<Home />} />
        <Route  path='/buy/student/:plan/' element={<Plans />} />
        <Route  path='/forgot-password/' element={<Forgot />} />
        <Route  path='/change-password/:studentid/:token/' element={<RecoveryPwd />} />
        <Route  path='/active-account/:studentid/:token/' element={ <ActiveAccount />} />
        <Route  path='/register-student/' element={<CreateAccount />} />
        <Route  path='/login-student/' element={<LoginAccount />} />
        <Route  path='/expo/students/' element={<ExpoTeach />} />
        <Route  path='/proyect/student/' element={<Proyects />} />
        <Route  path='/wall/Teacher/:name/' element={<WalletTeacher />} />
        <Route  path='/register-teacher/' element={ <RegisterTeacher />} />
        <Route  path='/legal/:type/document/:from/' element={<DocumentsLegal />} />
        <Route  path='/window/student/:studentid/:type_window/' element={<Windows />} />
        <Route  path='/dashboard-student/:studentid/academic' element={<HomeStudent />} />
        <Route  path='/student/comment' element={''} />
        <Route  path='/course/:studentid/:key/:courseKey' element={<Course />} />
      </Routes>
    </Router>  
);

export default Paths
